
import {
  DataTableColumn,
  FilterableDataTableColumn,
} from '@/models/DataTableColumn'
import { Vue, Component, Prop } from 'vue-property-decorator'
import CUDataTableFilterSearchBar from './CUDataTableFilterSearchBar.vue'
import sidebar from '@/store/modules/sidebar'
import MoreFiltersSidebar from './MoreFiltersSidebar.vue'
import columns from '@/store/modules/columns'
import CUDataTableFilterInterface from '@/components/CUDataTableFilterInterface.vue'
import { TableViewFilterEvent } from '@/models/TableView'
import CUSavedViewsDropdown from '@/components/CUSavedViewsDropdown.vue'

@Component({
  components: {
    CUDataTableFilterSearchBar,
    CUDataTableFilterInterface,
    CUSavedViewsDropdown,
  },
})
export default class CUDataTableFiltersRow extends Vue {
  @Prop({ required: true }) readonly columns!: DataTableColumn[]
  @Prop({ type: Boolean, default: false }) readonly hideMoreFilters!: boolean
  @Prop({ type: Boolean, default: false }) readonly showSavedViewMenu!: boolean
  @Prop({ type: Boolean, default: false }) readonly persistSidebar!: boolean
  @Prop(Function) downloadMethod!: any

  columnsStore = columns

  get isSearchable(): boolean {
    return !!this.columns.filter((col) => col.filterBySearch).length
  }

  get filterableColumns(): FilterableDataTableColumn[] {
    return this.columnsStore.getFilterableColumns.filter(
      (col) => !col.filterHiddenOnTopBar && col.filterInterface
    )
  }

  updateSelectFilter({
    column,
    event,
  }: {
    column: DataTableColumn
    event: TableViewFilterEvent
  }): void {
    if (sidebar.isOpen && !this.persistSidebar) {
      sidebar.pop()
    }
    let { filterType, filterLogic, value } = event

    if (!filterType) {
      filterType = column.filterType
    }

    if (!filterLogic) {
      filterLogic = 'and'
    }

    this.columnsStore.updateFilters({
      column,
      value,
      filterType,
      filterLogic,
    })
  }

  updateMultiSelectFilter({
    column,
    event,
  }: {
    column: DataTableColumn
    event: TableViewFilterEvent
  }): void {
    if (sidebar.isOpen && !this.persistSidebar) {
      sidebar.pop()
    }
    const { filterType } = column
    const value = event

    this.columnsStore.updateFilters({
      column,
      value,
      filterType,
    })
  }

  updateDateRangeFilter({
    column,
    event,
  }: {
    column: DataTableColumn
    event: TableViewFilterEvent
  }): void {
    if (sidebar.isOpen && !this.persistSidebar) {
      sidebar.pop()
    }
    const value = event
    const filterType = column.filterType
    this.columnsStore.updateFilters({
      column,
      value,
      filterType,
    })
  }

  updatePriceRangeFilter({
    column,
    event,
  }: {
    column: DataTableColumn
    event: TableViewFilterEvent
  }): void {
    if (sidebar.isOpen && !this.persistSidebar) {
      sidebar.pop()
    }
    const value = event
    const filterType = column.filterType
    this.columnsStore.updateFilters({
      column,
      value,
      filterType,
    })
  }

  clearFilters(): void {
    if (sidebar.isOpen && !this.persistSidebar) {
      sidebar.pop()
    }
    this.$refs['filter-search-bar']['clear']()
    columns.clearAllFilters()
  }

  openMoreFilters(): void {
    sidebar.push({
      component: MoreFiltersSidebar,
      props: {},
      width: 402,
      title: 'Filters',
    })
  }

  get areFilterButtonsVisible(): boolean {
    return !!this.columnsStore.getFilterableColumns.filter(
      (col) => !!col.filterInterface
    ).length
  }
}
