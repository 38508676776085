
import { Vue, Component, Prop } from 'vue-property-decorator'
import columnState from '@/store/modules/columns'
import column2State from '@/store/modules/columns2'
@Component({})
export default class CUDataTableFilterSearchBar extends Vue {
  @Prop({ required: false, default: 'Search' }) readonly placeholder!: string
  @Prop({ type: Boolean, default: false }) isV2!: boolean
  searchQuery = ''

  updateSearchQuery(e: string): void {
    this.searchQuery = e
    if (!this.isV2) {
      columnState.updateSearchFilters(e)
    } else {
      column2State.updateSearchFilters(e)
    }
    this.$emit('input', e)
  }

  clear(): void {
    this.updateSearchQuery('')
  }
}
