
import { Vue, Component } from 'vue-property-decorator'
import CUDataTableSavedViewsSidebar from '@/components/CUDataTableSavedViewsSidebar.vue'
import sidebar from '@/store/modules/sidebar'
import { SavedView } from '@/models/dto/SavedView'
import columns from '@/store/modules/columns'
@Component({
  components: {},
})
export default class CUDataTableSavedViewsRow extends Vue {
  savedViewsModel = []
  columns = columns

  openSavedViewsSidebar(): void {
    sidebar.popAllAndPush({
      component: CUDataTableSavedViewsSidebar,
      title: 'Edit Views',
    })
  }

  showLock(savedView: SavedView): boolean {
    return (
      savedView.viewSettings.predefined &&
      columns.getCurrentSavedViewId === savedView.savedViewId
    )
  }

  // Get a static width for a given saved view btn
  // Prevents shifting when switching from bold/not bold labeling inside
  getSavedViewBtnWidth(savedView: SavedView): number {
    let width = Math.round(savedView.name.length * 7.75) + 24
    if (this.showLock(savedView)) {
      width += 30
    }
    return width
  }

  isViewSelected(savedView: SavedView): boolean {
    return columns.getCurrentSavedViewId !== savedView.savedViewId
  }
}
