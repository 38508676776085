
import { Prop, Watch } from 'vue-property-decorator'
import DateMixin from '@/mixins/DateMixin'
import Component, { mixins } from 'vue-class-component'
import CUDataTable from '@/components/CUDataTable.vue'
import {
  TableViewFilter,
  TableViewParameters,
  TableViewSort,
} from '@/models/TableView'
import { DataTableColumn } from '@/models/DataTableColumn'
import { EventBus } from '@/utils/eventBus'
import { filter } from '@/utils/filter'
import { sort } from '@/utils/sort'
import { selectedColumn } from '@/utils/selectedColumn'
import { AxiosResponse } from 'axios'
import { TableAction } from '@/models/TableAction'
import CUDataTableFiltersRow from '@/components/CUDataTableFiltersRow.vue'
import CUDataTableSavedViewsRow from '@/components/CUDataTableSavedViewsRow.vue'
import columns from '@/store/modules/columns'
import { v4 } from 'uuid'
import {
  CurrentSort,
  SavedView,
  SavedViewViewSettings,
} from '@/models/dto/SavedView'
import savedViewService from '@/services/savedViews'
import deepClone from '@/utils/deepClone'
import { saveAs } from 'file-saver'
import auth from '@/store/modules/auth'
import dayjs from 'dayjs'

@Component({
  components: { CUDataTable, CUDataTableFiltersRow, CUDataTableSavedViewsRow },
})
export default class CUCollectionTable extends mixins(DateMixin) {
  @Prop({ type: Array, required: false, default: () => [] })
  columns!: DataTableColumn[]
  @Prop({ type: String, required: true }) collection!: string
  @Prop({ type: Array, required: false, default: () => [] })
  actions!: TableAction[]
  @Prop({ type: String, required: true }) itemKey!: string
  @Prop({ type: Boolean, default: false }) readonly hideMoreFilters!: boolean
  @Prop({ type: Boolean, default: false }) readonly hideSavedViews!: boolean
  @Prop({ type: Boolean, default: false }) readonly showSavedViewMenu!: boolean
  @Prop({ type: Boolean, default: false })
  readonly persistSidebarOnFilterUpdate!: boolean
  @Prop(Function) fetchMethod!: any
  @Prop(Function) downloadMethod!: any
  @Prop({ type: String, required: false, default: 'download.csv' })
  downloadName!: string
  @Prop({ required: false, default: () => [] })
  initialFilters!: TableViewFilter[]
  @Prop({ type: Boolean, required: false, default: false })
  isFilterDialogOpen: boolean
  @Prop({ type: Boolean, required: false, default: false })
  customizeColumns: boolean
  @Prop({ type: String, required: false })
  noDataText!: string
  @Prop({ required: false, default: '26px' })
  titleSize: number | string
  @Prop({ type: Boolean, required: false, default: false })
  singleSelect: boolean
  @Prop({ type: String, required: false })
  tooltipText: string

  @Prop({ required: false, default: () => [] }) initialSavedViews: SavedView[]
  @Prop({ required: false, default: () => ({}) }) readonly tableClasses: Record<
    string,
    string | boolean
  >
  @Prop({ required: false, default: false, type: Boolean })
  hideCollectionTitle!: boolean
  @Prop({ required: false, default: '' }) tableId: string
  @Prop({ required: false, default: () => ({}) }) headerClasses!: Record<
    string,
    string | boolean
  >
  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  hideSelect!: boolean

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  flat!: boolean

  items: unknown[] = []
  loading = false
  serverItemsLength = 0
  debounce: ReturnType<typeof setTimeout> | null = null
  loadingDebounce: ReturnType<typeof setTimeout> | null = null
  initialFiltersSet = false
  searchQuery = ''
  filters = filter()
  sorts = sort()
  selectedColumns = selectedColumn()

  options: TableViewParameters = {
    page: 1,
    pageSize: 25,
  }

  @Watch('loading')
  onLoaded(): void {
    if (!this.loading) {
      this.$emit('loaded', this.serverItemsLength)
    }
  }

  @Watch('serverItemsLength')
  serverItemsLengthUpdated(): void {
    this.$emit('update:count', this.serverItemsLength)
  }

  @Watch('allFilters', { deep: true, immediate: true })
  onFilterUpdate(): void {
    if (this.debounce) {
      clearTimeout(this.debounce)
    }
    this.debounce = setTimeout(this.refreshFilters, 500)
  }

  get titleFontSize(): string {
    if (typeof this.titleSize === 'number') {
      return String(this.titleSize)
    }
    return this.titleSize.replace(/[^\d]/gi, '')
  }

  get allFilters(): {
    singleSelectFilters: TableViewFilter[]
    searchBarFilters: TableViewFilter[]
    multiSelectFilters: TableViewFilter[]
  } {
    return columns.getFilters
  }

  get isFilterRowDisplayed(): boolean {
    if (this.downloadMethod) {
      return true
    }
    const filterableColumns = this.columns.filter(
      (col) => col.filterBySearch || col.filterInterface
    )
    if (filterableColumns.length) {
      return true
    }
    return false
  }

  get areInitialFiltersSet(): boolean {
    return this.initialFilters.length && !this.initialFiltersSet
  }

  get visibleColumns(): DataTableColumn[] {
    return this.columns.filter((column) => !column.hidden)
  }

  get filteredListeners() {
    const handledEvents = [
      'update:options',
      'refresh',
      'update:rows-per-page',
      'handle-excel-download',
      'handle-pdf-download',
    ]

    const filtered = Object.keys(this.$listeners).reduce((acc, key) => {
      if (!handledEvents.includes(key)) {
        acc[key] = this.$listeners[key]
      }
      return acc
    }, {})

    return filtered
  }

  refreshFilters(): void {
    this.filters.clear()
    if (
      !columns.singleSelectFilters.length &&
      !columns.searchBarFilters.length &&
      !columns.multiSelectFilters.length
    ) {
      this.loadTableViewWithDebounce()
      return
    }

    // For each of these filters, add it to the filters util
    const topLevelFilter = this.filters.createParent('and')

    for (const filterToAdd of columns.getSingleSelectFilters) {
      const logic = filterToAdd.column.filterLogic || 'and'
      const parentFilter = this.filters.createParent(logic, topLevelFilter)
      this.filters.add(parentFilter, filterToAdd)
    }

    if (columns.getMultiSelectFilters.length) {
      const parentMultiSelectFilter = this.filters.createParent(
        'and',
        topLevelFilter
      )

      const multiFilters = columns.getMultiSelectFilters.reduce((res, f) => {
        if (res[f.columnId]) {
          res[f.columnId].push(f)
        } else {
          Object.assign(res, { [f.columnId]: [f] })
        }
        return res
      }, {})

      for (const key of Object.keys(multiFilters)) {
        if (multiFilters[key].length === 1) {
          const subParentMultiSelectFilter = this.filters.createParent(
            'and',
            parentMultiSelectFilter
          )
          this.filters.add(subParentMultiSelectFilter, multiFilters[key][0])
        } else {
          const subParentMultiSelectFilter = this.filters.createParent(
            'or',
            parentMultiSelectFilter
          )
          for (const filter of multiFilters[key]) {
            this.filters.add(subParentMultiSelectFilter, filter)
          }
        }
      }
    }

    if (!columns.searchBarFilters.length) {
      this.loadTableViewWithDebounce()
      return
    }

    const searchBarParentFilter = this.filters.createParent(
      'and',
      topLevelFilter
    )
    const searchBarFilter = this.filters.createParent(
      'or',
      searchBarParentFilter
    )

    for (const filterToAdd of columns.searchBarFilters) {
      this.filters.add(searchBarFilter, filterToAdd)
    }

    this.loadTableViewWithDebounce()
  }

  async loadSavedViews(
    data: { tableId: string; viewId: number } | undefined = undefined
  ): Promise<void> {
    if (!this.tableId || (data?.tableId && data?.tableId !== this.tableId)) {
      return
    }
    const userRes = await savedViewService.getUserViewsForTable(this.tableId)

    const userSavedViews = userRes.data.savedViews?.map((sv) => {
      const viewSettings = JSON.parse(sv.viewSettings) as SavedViewViewSettings
      return { ...sv, viewSettings }
    })
    columns.setSavedViews(userSavedViews)
    const initialSavedViews = deepClone(this.initialSavedViews).map((sv) => ({
      ...sv,
      viewSettings: { ...sv.viewSettings, pinned: true, predefined: true },
    }))
    userSavedViews.sort(
      (a, b) => a.viewSettings.orderIndex - b.viewSettings.orderIndex
    )

    const savedViews = [...initialSavedViews, ...userSavedViews]
    columns.setSavedViews(savedViews)

    if (data?.viewId) {
      const viewToApply = savedViews.find((c) => c.savedViewId === data.viewId)
      if (viewToApply) {
        columns.applySavedView(viewToApply)
        return
      }
    }

    const defaultView =
      savedViews.find((sv) => sv.isDefault) || savedViews.find((sv) => !!sv)
    if (defaultView) {
      columns.applySavedView(defaultView)
    }
  }

  async handleExcelDownload(): Promise<void> {
    const sorts = this.sorts.asQueryParams()
    const filters = this.filters.asQueryParams()
    const selectedColumns = this.selectedColumns.asQueryParams(
      columns.tableColumns
    )
    const params = {
      sorts,
      filters,
      selectedColumns,
    }

    const tz = auth?.getUser?.timeZone || dayjs.tz.guess()
    const date = this.formatLongDate(dayjs().toISOString(), { tz })
    const time = dayjs().tz(tz).format('h:mm:ss A z')
    const downloadName = `${this.downloadName} ${date} ${time}.csv`

    const downloadData = await this.downloadMethod(params)
    if (downloadData) {
      await saveAs(downloadData.data, downloadName)
    }
  }

  async handlePdfDownload(): Promise<void> {
    // Set at 100 due to Query String Limitations
    const MAX_RESERVATIONS = 100
    if (this.serverItemsLength > MAX_RESERVATIONS) {
      EventBus.$emit('snackbar:error', '100 reservations maximum exceeded')
      return
    }

    const sorts = this.sorts.asQueryParams()
    const filters = this.filters.asQueryParams()

    const tz = auth?.getUser?.timeZone || dayjs.tz.guess()

    const response: AxiosResponse = await this.fetchMethod({
      pageSize: MAX_RESERVATIONS,
      page: 1,
      filters,
      sorts,
      tz,
    })
    const { data } = response

    const resultList: unknown[] = data.resultList

    const items = (resultList || []).map((item: any) => {
      const obj = { id: item[this.itemKey] }
      return Object.assign({}, item, obj)
    })
    this.$emit('handle-pdf-download', items)
  }

  async mounted(): Promise<void> {
    EventBus.$on('set-tableview-page', this.setTableViewPage)
    EventBus.$on('refresh-tableview', this.loadTableViewWithDebounce)
    EventBus.$on('saved-views:refresh', this.loadSavedViews)
    EventBus.$on('table-views:set-options', this.updateOptions)

    if (this.customizeColumns) {
      columns.setColumnsWithCustomizeHeader(this.columns)
      columns.initializeFilterableColumns(this.columns)
    } else {
      columns.setColumns(this.columns)
    }

    columns.setTableId(this.tableId)

    await this.loadSavedViews()

    this.loadTableViewWithDebounce()
  }

  beforeDestroy(): void {
    EventBus.$off('set-tableview-page', this.setTableViewPage)
    EventBus.$off('refresh-tableview', this.loadTableViewWithDebounce)
    EventBus.$off('saved-views:refresh', this.loadSavedViews)
    EventBus.$off('table-views:set-options', this.updateOptions)
  }

  setTableViewPage(page: number): void {
    this.options.page = page
  }

  destroyed(): void {
    columns.clearColumns()
    columns.clearSavedViews()
    columns.clearAllFilters()
    columns.clearCurrentSorts()
    columns.clearTableId()
  }

  updateOptions({
    pageSize,
    currentSort,
  }: {
    pageSize: number
    currentSort: CurrentSort
  }): void {
    if (currentSort) {
      this.sorts.add(currentSort)
    } else {
      this.sorts.remove()
    }

    const sortDesc = currentSort ? [currentSort.direction !== 'asc'] : []
    const sortBy = currentSort ? [currentSort.prop] : []

    this.options = {
      ...this.options,
      sortDesc,
      sortBy,
      pageSize,
    }

    this.loadTableViewWithDebounce()
  }

  handleUpdateOptions(e): void {
    this.options = e
    if (e?.sortBy?.length && e?.sortDesc?.length) {
      const columnValue = e.sortBy[0]
      const sortDesc = e.sortDesc[0]
      const column = this.columns.find((col) => col.value === columnValue)
      if (!column) {
        return
      }
      const sortObject: TableViewSort = {
        id: v4(),
        key: v4(),
        direction: sortDesc ? 'desc' : 'asc',
        prop: column.sortProp || column.value,
      }
      columns.addColumnSort(sortObject)
      this.sorts.add(sortObject)
    } else {
      this.sorts.remove()
    }
    this.loadTableViewWithDebounce()
  }

  loadTableViewWithDebounce(): void {
    if (this.loadingDebounce) {
      window.clearTimeout(this.loadingDebounce)
    }

    this.loadingDebounce = setTimeout(() => {
      this.load()
    }, 500)
  }

  async load(): Promise<void> {
    if (this.areInitialFiltersSet) {
      return
    }

    this.loading = true

    // Handles Sync With Computer option for user timezone
    const tz = !auth?.getUser?.timeZone ? dayjs.tz.guess() : null

    await this.$nextTick(async () => {
      const response: AxiosResponse = await this.fetchMethod({
        pageSize: this.options.pageSize,
        page: this.options.page,
        filters: this.filters.asQueryParams(),
        sorts: this.sorts.asQueryParams(),
        tz,
      })
      const { data } = response

      this.serverItemsLength = data.count || 0

      const items: unknown[] = data.resultList

      this.items = (items || []).map((item: any) => {
        const obj = { id: item[this.itemKey] }
        return Object.assign({}, item, obj)
      })
      this.loading = false
    })
  }

  updateRowsPerPage(e: number): void {
    if (Math.ceil(this.serverItemsLength / e) < this.options.page) {
      this.options.page = 1
    }
    this.options.pageSize = e
    columns.setPageSize(e)
    this.loadTableViewWithDebounce()
  }
}
