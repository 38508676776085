
import { Vue, Component } from 'vue-property-decorator'
import draggable from 'vuedraggable'
import sidebar from '@/store/modules/sidebar'
import _cloneDeep from 'lodash.clonedeep'
import columns from '@/store/modules/columns'
import deepClone from '@/utils/deepClone'
import {
  EditSavedViewRequest,
  NewSavedViewRequest,
  SavedView,
  SavedViewColumn,
  SavedViewViewSettings,
} from '@/models/dto/SavedView'
import { EventBus } from '@/utils/eventBus'
import savedViewService from '@/services/savedViews'
import CUDataTableSavedViewsSidebarListItem from '@/components/CUDataTableSavedViewsSidebarListItem.vue'
@Component({
  components: { draggable, CUDataTableSavedViewsSidebarListItem },
})
export default class CUDataTableSavedViewsSidebar extends Vue {
  columns = columns
  draggedIndex: number | null = null
  sidebar = sidebar
  searchViewsQuery = ''
  visibleViews: SavedView[] = _cloneDeep(this.columns.getAllSavedViews).filter(
    (col) => !col.viewSettings.predefined
  )
  viewsToDelete = []

  get currentSavedViewId(): number {
    return columns.getCurrentSavedViewId
  }

  get predefinedViews(): SavedView[] {
    return this.columns.getAllSavedViews.filter(
      (col) => col.viewSettings.predefined
    )
  }

  updateViewName(savedView: SavedView, newName: string): void {
    savedView.name = newName
  }

  deleteView(savedView: SavedView): void {
    this.visibleViews = this.visibleViews.filter(
      (v) => v.savedViewId !== savedView.savedViewId
    )
    this.viewsToDelete.push(savedView)
  }

  updatePinned(savedView: SavedView, pinned: boolean): void {
    savedView.viewSettings.pinned = pinned
    if (!pinned) {
      savedView.isDefault = false
    }
  }

  updateDefault(savedView: SavedView, isDefault: boolean): void {
    for (const view of this.visibleViews) {
      if (view.savedViewId === savedView.savedViewId) {
        view.isDefault = isDefault
        if (isDefault) {
          view.viewSettings.pinned = true
        }
      } else {
        view.isDefault = false
      }
    }
  }

  start(e: { oldIndex }): void {
    this.draggedIndex = e.oldIndex
  }

  end(): void {
    this.draggedIndex = null
  }

  buildViewPayload(): NewSavedViewRequest {
    const columnsToSave = deepClone(columns.getAllColumns)
      .filter((col) => col.type !== 'customize-columns')
      .map((col) => {
        let savedCol: SavedViewColumn = {
          _t_id: col._t_id,
          hidden: col.hidden,
        }
        if (col.filterValue != null) {
          savedCol = { ...savedCol, filterValue: col.filterValue }
        }
        return savedCol
      })

    const currentSort = columns.getCurrentSort

    const viewSettings: SavedViewViewSettings = {
      columns: columnsToSave,
      pinned: true,
      orderIndex: this.visibleViews.length,
      pageSize: columns.getPageSize,
      currentSort,
    }
    const payload: NewSavedViewRequest = {
      name: columns.getCurrentSavedView.name,
      tableId: columns.getTableId,
      viewSettings: JSON.stringify(viewSettings),
    }

    return payload
  }

  async updateCurrentView(): Promise<void> {
    const payload: NewSavedViewRequest = this.buildViewPayload()
    await savedViewService.edit(columns.getCurrentSavedViewId, payload)
    EventBus.$emit('saved-views:refresh', {
      tableId: columns.getTableId,
      viewId: columns.getCurrentSavedViewId,
    })
    sidebar.pop()
  }

  async applyLocalChanges(): Promise<void> {
    const deletePromises = this.viewsToDelete.map((v) =>
      savedViewService.delete(v.savedViewId)
    )
    const modifyPromises = this.visibleViews.map((viewToModify, orderIndex) => {
      viewToModify.viewSettings.orderIndex = orderIndex
      const payload: EditSavedViewRequest = {
        name: viewToModify.name,
        isDefault: viewToModify.isDefault,
        viewSettings: JSON.stringify(viewToModify.viewSettings),
      }
      return savedViewService.edit(viewToModify.savedViewId, payload)
    })

    await Promise.all([...deletePromises, ...modifyPromises])
    EventBus.$emit('saved-views:refresh', {
      tableId: columns.getTableId,
      viewId: columns.getCurrentSavedViewId,
    })
    sidebar.pop()
  }
}
