
import {
  NewSavedViewRequest,
  SavedViewColumn,
  SavedViewViewSettings,
} from '@/models/dto/SavedView'
import columns from '@/store/modules/columns'
import sidebar from '@/store/modules/sidebar'
import deepClone from '@/utils/deepClone'
import savedViewService from '@/services/savedViews'
import { Vue, Component, Inject } from 'vue-property-decorator'
import { EventBus } from '@/utils/eventBus'
import CUDataTableSavedViewSaveAsNewSidebar from '@/components/CUDataTableSavedViewSaveAsNewSidebar.vue'

@Component({ components: {} })
export default class CUSavedViewsDropdown extends Vue {
  @Inject({ default: 0 }) mobileRowBreakpoint: number

  columns = columns
  viewsToDelete = []
  viewNameErrors = []

  get isCurrentViewPredefined(): boolean {
    return !!columns?.getCurrentSavedView?.viewSettings?.predefined
  }

  get title(): string {
    if (this.isCurrentViewPredefined) {
      return 'Read-only View'
    }
    return 'Editable View'
  }

  get description(): string {
    if (this.isCurrentViewPredefined) {
      return 'This is a default Busify view and cannot be edited.'
    }
    return 'This is one of your saved views, which you can make changes to.'
  }

  get visibleViewsLength(): number {
    return this.columns.getAllSavedViews.filter(
      (col) => !col.viewSettings.predefined
    ).length
  }

  buildViewPayload(
    options: {
      isNew: boolean
    } = { isNew: false }
  ): Partial<NewSavedViewRequest> {
    const columnsToSave = deepClone(columns.getAllColumns)
      .filter((col) => col.type !== 'customize-columns')
      .map((col) => {
        let savedCol: SavedViewColumn = {
          _t_id: col._t_id,
          hidden: col.hidden,
        }
        if (col.filterValue != null) {
          savedCol = {
            ...savedCol,
            filterValue: col.filterValue,
            filterType: col.filterType,
            filterLogic: col.filterLogic,
          }
        }
        return savedCol
      })

    const currentSort = columns.getCurrentSort

    const viewSettings: SavedViewViewSettings = {
      columns: columnsToSave,
      pinned: true,
      orderIndex: options.isNew
        ? this.visibleViewsLength
        : columns.getCurrentSavedView.viewSettings.orderIndex,
      pageSize: columns.getPageSize,
      currentSort,
    }
    const payload: Partial<NewSavedViewRequest> = {
      tableId: columns.getTableId,
      viewSettings: JSON.stringify(viewSettings),
    }

    return payload
  }

  async updateCurrentView(): Promise<void> {
    const payload: Partial<NewSavedViewRequest> = this.buildViewPayload()
    await savedViewService.edit(columns.getCurrentSavedViewId, payload)
    EventBus.$emit('saved-views:refresh', {
      tableId: columns.getTableId,
      viewId: columns.getCurrentSavedViewId,
    })
    sidebar.pop()
  }

  saveAsNewView(): void {
    sidebar.push({
      component: CUDataTableSavedViewSaveAsNewSidebar,
      title: 'Save New View',
      props: {
        newSavedViewRequest: this.buildViewPayload({ isNew: true }),
      },
    })
  }
}
