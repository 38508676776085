
import { SavedView } from '@/models/dto/SavedView'
import { Vue, Component, Prop } from 'vue-property-decorator'
import columns from '@/store/modules/columns'

@Component({})
export default class CUDataTableSavedViewsSidebarView extends Vue {
  @Prop({ required: true })
  readonly view!: SavedView

  @Prop({ required: false, type: Boolean, default: false })
  readonly predefined!: boolean

  @Prop({ required: false, type: Boolean, default: false })
  readonly dragIsActive: boolean

  columns = columns

  showEditNameIcon = false
  newViewName = this.view.name
  editingName = false
  error = ''

  get isNewSavedViewNameDuplicate(): boolean {
    const savedViewNames = this.columns.getAllSavedViews
      .filter((view) => view.savedViewId !== this.view.savedViewId)
      .map(({ name }) => name)
    return savedViewNames.includes(this.newViewName)
  }

  get name(): string {
    if (this.newViewName) {
      return this.newViewName
    }
    return this.view.name
  }

  handleNameInput(): void {
    this.error = ''
  }

  setNewName(): void {
    if (!this.newViewName) {
      this.error = 'Must specify a name'
      return
    }
    if (this.isNewSavedViewNameDuplicate) {
      this.error = 'Must specify a unique name for view'
      return
    }
    this.editingName = false
    this.$emit('view:update-name', this.newViewName)
  }

  togglePinned(): void {
    this.$emit('update:pinned', !this.view.viewSettings.pinned)
  }

  toggleDefault(): void {
    this.$emit('update:default', !this.view.isDefault)
  }

  deleteView(): void {
    this.$emit('saved-views:delete-view')
  }
}
