import { DataTableColumn } from '@/models/DataTableColumn'

/* eslint-disable @typescript-eslint/no-explicit-any */
export const selectedColumn = (): any => {
  const asQueryParams = (columns: DataTableColumn[]): string => {
    return columns
      .filter((col) => col.hidden === false && !col.omitExport)
      .reduce(
        (selectedColumn, col, index) =>
          `${selectedColumn}${
            index !== 0 ? '&' : ''
          }selectedColumn[${index}][hidden]=false&selectedColumn[${index}][title]=${
            col.text
          }&selectedColumn[${index}][field]=${col.label || col.value}`,
        ''
      )
  }

  return {
    asQueryParams,
  }
}
