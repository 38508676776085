
import { Vue, Component } from 'vue-property-decorator'
import columns from '@/store/modules/columns'
import _cloneDeep from 'lodash.clonedeep'
import {
  DataTableColumn,
  FilterableDataTableColumn,
} from '@/models/DataTableColumn'
import sidebar from '@/store/modules/sidebar'
import CUDataTableFilterInterface from '@/components/CUDataTableFilterInterface.vue'
import { TableViewFilterEvent, TableViewFilterValue } from '@/models/TableView'
import { isNotEmptyFilter } from '@/utils/filtering'
@Component({
  components: { CUDataTableFilterInterface },
})
export default class MoreFiltersSidebar extends Vue {
  filterableColumns = []
  inactiveFilterSearchQuery = ''
  sidebar = sidebar

  mounted(): void {
    this.filterableColumns = _cloneDeep(columns.getFilterableColumns)
  }

  updateSelectableFilter({
    column,
    event,
  }: {
    column: DataTableColumn
    event: TableViewFilterEvent
  }): void {
    const { filterType, value } = event
    column.filterValue = value
    if (filterType) {
      column.filterType = filterType
    }
  }

  updateMultiSelectFilter({
    column,
    event,
  }: {
    column: DataTableColumn
    event: TableViewFilterValue
  }): void {
    column.filterValue = event
  }

  syncFilters(): void {
    columns.syncFilterableColumns(this.filterableColumns)
    sidebar.pop()
  }

  clear(): void {
    columns.clearAllFilters()
    sidebar.pop()
  }

  get activeFilterableColumns(): FilterableDataTableColumn[] {
    return this.filterableColumns.filter((col) =>
      isNotEmptyFilter(col.filterValue, col)
    )
  }

  get inactiveFilterableColumns(): FilterableDataTableColumn[] {
    return this.filterableColumns.filter(
      (col) =>
        col.filterInterface &&
        !isNotEmptyFilter(col.filterValue, col) &&
        col.text
          .toLowerCase()
          .includes(this.inactiveFilterSearchQuery.toLowerCase())
    )
  }
}
