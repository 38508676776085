var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('CUSidebarContent',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',[_c('CUTextField',{staticClass:"margin-y-3",attrs:{"placeholder":"Search views","hide-details":""},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('CUIcon',{style:({ marginTop: '3px', marginRight: '6px' }),attrs:{"width":"15px","height":"15px","color":"bg-gray-4"}},[_vm._v(" search ")])]},proxy:true}]),model:{value:(_vm.searchViewsQuery),callback:function ($$v) {_vm.searchViewsQuery=$$v},expression:"searchViewsQuery"}}),_vm._l((_vm.predefinedViews),function(predefinedView,predefinedViewIndex){return [(
            predefinedView.name
              .toUpperCase()
              .includes(_vm.searchViewsQuery.toUpperCase())
          )?_c('CUDataTableSavedViewsSidebarListItem',{key:`predefined-view-${predefinedViewIndex}-${predefinedView.savedViewId}`,attrs:{"view":predefinedView,"predefined":""},on:{"view:update-name":function($event){return _vm.updateViewName(predefinedView, $event)}}}):_vm._e()]}),_c('draggable',{on:{"start":_vm.start,"end":_vm.end},model:{value:(_vm.visibleViews),callback:function ($$v) {_vm.visibleViews=$$v},expression:"visibleViews"}},[_vm._l((_vm.visibleViews),function(view,viewIndex){return [(
              view.name.toUpperCase().includes(_vm.searchViewsQuery.toUpperCase())
            )?_c('CUDataTableSavedViewsSidebarListItem',{key:`saved-view-${viewIndex}-${view.savedViewId}`,attrs:{"view":view,"drag-is-active":_vm.draggedIndex === viewIndex},on:{"update:pinned":function($event){return _vm.updatePinned(view, $event)},"update:default":function($event){return _vm.updateDefault(view, $event)},"saved-views:delete-view":function($event){return _vm.deleteView(view)},"view:update-name":function($event){return _vm.updateViewName(view, $event)}}}):_vm._e()]})],2)],2)]},proxy:true},{key:"footer",fn:function(){return [_c('CUSidebarContentWithFooterButtons',{attrs:{"primary-text":"Apply"},on:{"update:secondary":_vm.sidebar.pop,"update:primary":_vm.applyLocalChanges}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }