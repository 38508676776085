
import { DataTableColumn } from '@/models/DataTableColumn'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import columns from '@/store/modules/columns'
import { isNotEmptyFilter } from '@/utils/filtering'
import { TableViewFilterValue } from '@/models/TableView'
import auth from '@/store/modules/auth'
@Component({})
export default class CUDataTableFilterInterface extends Vue {
  @Prop({
    required: true,
  })
  column!: DataTableColumn

  @Watch('column.filterValue', { immediate: true })
  onColumnFilterValueChange(): void {
    if (this.column.filterInterface === 'date-range') {
      this.datePickerValue = this.column.filterValue as string[]
    }
  }

  // Store the value of the current date picker so that we can emit it when the user
  // confirms the date range
  datePickerValue: string[] = []

  get isFilterEmpty(): boolean {
    return !isNotEmptyFilter(this.column.filterValue, this.column)
  }

  get datePickerPlaceholder(): string {
    return auth.shouldDisplayDayLeading ? 'DD/MM/YY' : 'MM/DD/YY'
  }

  get borderColor(): string {
    if (!this.isFilterEmpty) {
      return 'green'
    }
    return 'gray-border-mid'
  }

  updateSelectFilter(
    column: DataTableColumn,
    {
      filterType,
      value,
    }: { filterType: string | undefined; value: string | number }
  ): void {
    if (!filterType) {
      filterType = column.filterType
    }
    columns.updateFilters({
      column,
      value,
      filterType,
    })
  }

  updateDateRangeFilter(column: DataTableColumn, value: string[]): void {
    const filterType = column.filterType
    columns.updateFilters({
      column,
      value,
      filterType,
    })
  }

  handleMultiSelectInput(e: TableViewFilterValue): void {
    this.$emit('update:multi-select', { column: this.column, event: e })
  }

  handleDatePickerInput(e: TableViewFilterValue): void {
    this.datePickerValue = e as string[]
  }

  handleDatePickerConfirmation(): void {
    this.$emit('update:date-range-picker', {
      column: this.column,
      event: this.datePickerValue,
    })
  }

  handleDatePickerClear(): void {
    this.$emit('update:date-range-picker', {
      column: this.column,
      event: [],
    })
  }
}
