
import { NewSavedViewRequest, SavedView } from '@/models/dto/SavedView'
import columns from '@/store/modules/columns'
import sidebar from '@/store/modules/sidebar'
import _cloneDeep from 'lodash.clonedeep'
import savedViewService from '@/services/savedViews'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { EventBus } from '@/utils/eventBus'

@Component({})
export default class CUDataTableSavedViewSaveAsNewSidebar extends Vue {
  @Prop({ default: false, required: true })
  readonly newSavedViewRequest!: NewSavedViewRequest

  columns = columns
  sidebar = sidebar
  visibleViews: SavedView[] = _cloneDeep(this.columns.getAllSavedViews).filter(
    (col) => !col.viewSettings.predefined
  )
  viewNameErrors = []
  newSavedViewName = ''

  get isNewSavedViewNameDuplicate(): boolean {
    const savedViewNames = this.columns.getAllSavedViews.map(({ name }) => name)
    return savedViewNames.includes(this.newSavedViewName)
  }

  async saveAsNewView(): Promise<void> {
    if (!this.newSavedViewName) {
      this.viewNameErrors = ['Must specify a name']
      return
    }
    if (this.isNewSavedViewNameDuplicate) {
      this.viewNameErrors = ['Must specify new name for view']
      return
    }
    this.newSavedViewRequest.name = this.newSavedViewName
    const res = await savedViewService.addForUser(this.newSavedViewRequest)
    EventBus.$emit('saved-views:refresh', {
      tableId: columns.getTableId,
      viewId: res.data.savedView.savedViewId,
    })
    sidebar.pop()
  }
}
