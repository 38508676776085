import {
  EditSavedViewRequest,
  GetSavedViewResult,
  NewSavedViewRequest,
  NewSavedViewResult,
  SavedView,
} from '@/models/dto/SavedView'
import { apiBaseUrl } from '@/utils/env'
import { AxiosResponse } from 'axios'
import { HttpService } from './common/HttpService'
import auth from '@/store/modules/auth'
import { ApiResult } from '@/models/dto/ApiResult'
const httpService: HttpService = new HttpService()

export default {
  getUserViewsForTable(
    tableId: string
  ): Promise<AxiosResponse<GetSavedViewResult>> {
    const userId = auth.getUserId
    const companyId = auth.getUser.companyId

    const url = `https://${apiBaseUrl()}/savedViews/${tableId}/${companyId}/${userId}`
    return httpService.get(url)
  },
  getCompanyViewsForTable(
    tableId: string
  ): Promise<AxiosResponse<GetSavedViewResult>> {
    const companyId = auth.getUser.companyId

    const url = `https://${apiBaseUrl()}/savedViews/${tableId}/${companyId}`
    return httpService.get(url)
  },
  addForUser(
    payload: NewSavedViewRequest
  ): Promise<AxiosResponse<NewSavedViewResult>> {
    const userId = auth.getUserId
    const companyId = auth.getUser.companyId
    const url = `https://${apiBaseUrl()}/savedViews/${companyId}/${userId}`
    return httpService.post(url, payload)
  },
  edit(viewId: number, payload: EditSavedViewRequest): Promise<AxiosResponse> {
    const url = `https://${apiBaseUrl()}/savedViews/${viewId}`
    return httpService.patch(url, payload)
  },
  delete(viewId: string): Promise<AxiosResponse<ApiResult>> {
    const url = `https://${apiBaseUrl()}/savedViews/${viewId}`
    return httpService.delete(url)
  },
}
